<template>
  <div>
    <h3 class="primary--text text-center text-uppercase font-weight-regular">
      Selecciona servicio
    </h3>
    <v-row>
      <v-col cols="12" md="2" class="py-0" offset-md="1">
        <v-text-field
          outlined
          dense
          hide-details
          v-model="search"
          single-line
          clearable
          :label="$t('search')"
          prepend-inner-icon="mdi-magnify"
          @change="model = 0"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-radio-group v-model="customer.sub_type">
      <v-slide-group v-model="model" show-arrows>
        <v-slide-item
          v-for="service in subServiceFiltered"
          :key="service.id"
          v-slot="{ toggle }"
          center-active
        >
          <v-card
            @click="
              select(service);
              toggle();
            "
            width="300px"
            class="mx-1 my-1"
            v-bind:class="[
              {
                select: customer.sub_type === service.description,
              },
            ]"
          >
            <v-radio class="radio-card" :value="service.description"></v-radio>
            <v-card-title>
              {{ service.description }} <br />
              {{
                servicePrice()(service)
                  ? $n(servicePrice()(service), "currency")
                  : ""
              }}
            </v-card-title>
            <v-card-text>
              {{
                $tc("services.info", service.sesions, {
                  count: service.sesions,
                  time: formatTime()(service.time),
                })
              }}
            </v-card-text>

            <div class="close">
              <v-dialog
                v-if="service.url"
                width="500px"
                v-model="service.dialog"
              >
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click.stop="">
                    <v-icon small>$eye</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>{{ service.description }}</v-card-title>
                  <div class="close">
                    <v-btn icon @click="service.dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <v-img class="mt-2" :src="service.url" />
                </v-card>
              </v-dialog>
            </div>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-radio-group>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "ServiceSelector",
  data() {
    return {
      search: null,
      model: null,
    };
  },
  mounted() {
    this.fetchSubservice();
  },
  computed: {
    service_id() {
      return this.customer.type_id;
    },
    ...mapState("inscriptions", ["customerState", "subServicesState"]),
    sub_services: {
      get() {
        return this.subServicesState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_SUBSERVICES", value);
      },
    },
    subServiceFiltered() {
      if (this.search)
        return this.sub_services.filter((ss) =>
          ss.description
            .toLowerCase()
            .replace(" ", "")
            .includes(this.search.replace(" ", "").toLowerCase())
        );
      return this.sub_services;
    },
    customer: {
      get() {
        console.log(this.customerState);
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },
  },
  methods: {
    ...mapGetters("app", ["formatTime"]),
    ...mapGetters("inscriptions", ["servicePrice"]),
    select(part) {
      this.customer.sub_type = part.description;
      this.customer.sub_type_id = part.id;
      this.customer.body_part = null;
      this.customer.inner_part = null;
      this.customer.inner_part_other = null;
      this.$emit("selected", part);
    },
    ...mapActions("subservices", ["getSubServices"]),
    fetchSubservice() {
      this.getSubServices({
        filters: {
          studio_id:
            this.$route.name == "customer_inscription_studio"
              ? this.customer.studio.id
              : this.customer.tattooer.id,
          marketplace: true,
        },
        pagination: { itemPerPage: -1 },
        service_id: this.service_id,
      }).then((response) => {
        this.sub_services = response.data.map((ss) => {
          return { ...ss, dialog: false };
        });
      });
    },
  },
};
</script>