var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"primary--text text-center text-uppercase font-weight-regular"},[_vm._v(" Selecciona servicio ")]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2","offset-md":"1"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","single-line":"","clearable":"","label":_vm.$t('search'),"prepend-inner-icon":"mdi-magnify"},on:{"change":function($event){_vm.model = 0}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-radio-group',{model:{value:(_vm.customer.sub_type),callback:function ($$v) {_vm.$set(_vm.customer, "sub_type", $$v)},expression:"customer.sub_type"}},[_c('v-slide-group',{attrs:{"show-arrows":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.subServiceFiltered),function(service){return _c('v-slide-item',{key:service.id,attrs:{"center-active":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"mx-1 my-1",class:[
            {
              select: _vm.customer.sub_type === service.description,
            } ],attrs:{"width":"300px"},on:{"click":function($event){_vm.select(service);
            toggle();}}},[_c('v-radio',{staticClass:"radio-card",attrs:{"value":service.description}}),_c('v-card-title',[_vm._v(" "+_vm._s(service.description)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.servicePrice()(service) ? _vm.$n(_vm.servicePrice()(service), "currency") : "")+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$tc("services.info", service.sesions, { count: service.sesions, time: _vm.formatTime()(service.time), }))+" ")]),_c('div',{staticClass:"close"},[(service.url)?_c('v-dialog',{attrs:{"width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("$eye")])],1)]}}],null,true),model:{value:(service.dialog),callback:function ($$v) {_vm.$set(service, "dialog", $$v)},expression:"service.dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(service.description))]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){service.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-img',{staticClass:"mt-2",attrs:{"src":service.url}})],1)],1):_vm._e()],1)],1)]}}],null,true)})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }